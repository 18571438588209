import { Box, useResponsiveValue } from 'braid-design-system';
import { useState, useRef, useEffect } from 'react';

import { useAnalyticsFacade } from 'src/modules/AnalyticsFacade/react.ts';
import { useDispatch, useSelector } from 'src/store/react.ts';
import {
  selectIsResultsLoading,
  selectPills,
  selectSolMetadata,
} from 'src/store/results/selectors';
import { runSearch, updateKeywordsField } from 'src/store/search/index.ts';

import { LoadingPills } from './components/LoadingPills/LoadingPills.tsx';
import { Pills } from './components/Pills/Pills.tsx';
import { ScrollButton } from './components/ScrollButton/ScrollButton.tsx';

const isPillsAreInView = (refCurrent: HTMLDivElement) =>
  refCurrent.scrollWidth <
    Math.floor(refCurrent.clientWidth + refCurrent.scrollLeft) ||
  refCurrent.scrollWidth === refCurrent.clientWidth;

export const DynamicPill = () => {
  const solMetadata = useSelector(selectSolMetadata);
  const dispatch = useDispatch();
  const pills = useSelector(selectPills);
  const ref = useRef<HTMLDivElement>(null);
  const [isLastPillClicked, setIsLastPillClicked] = useState(false);
  const isLoading = useSelector(selectIsResultsLoading);
  const [isAtStart, setIsAtStart] = useState(true);
  const [isAtEnd, setIsAtEnd] = useState(true);
  const analyticsFacade = useAnalyticsFacade();
  const isMobile =
    useResponsiveValue()({
      mobile: true,
      tablet: false,
    }) || false;

  const handleOnPillClick = (
    label: string,
    keyword: string,
    lastPill: boolean,
    position: number,
    isActive: boolean,
  ) => {
    if (lastPill) {
      setIsLastPillClicked(true);
    }

    analyticsFacade.refinementSuggestionClicked({
      solMetadata,
      refinedKeyword: {
        isActive,
        label,
        position,
      },
    });

    dispatch(updateKeywordsField(keyword));
    dispatch(runSearch({ pathname: '/jobs' }));
  };

  const handleScroll = () => {
    const carouselDiv = ref.current;
    if (!carouselDiv) return;

    const maxScrollLeft = carouselDiv.scrollWidth - carouselDiv.clientWidth;
    const newScrollLeft = carouselDiv.scrollLeft;

    setIsAtStart(newScrollLeft === 0);
    setIsAtEnd(newScrollLeft >= maxScrollLeft - 1);
  };

  const handleOnScrollButtonClick =
    (direction: 'left' | 'right') => (event: React.MouseEvent<HTMLElement>) => {
      event.preventDefault();

      const carouselDiv = ref.current;
      if (carouselDiv) {
        const scrollAmount = carouselDiv.clientWidth * 0.9;
        carouselDiv.scrollBy({
          left: direction === 'left' ? -scrollAmount : scrollAmount,
          behavior: 'smooth',
        });
      }
    };

  useEffect(() => {
    // Reset state when loading state
    setIsAtStart(true);

    const currentRef = ref.current;
    if (currentRef) {
      setIsAtEnd(isPillsAreInView(currentRef));
    } else {
      setIsAtEnd(false);
    }
  }, [isLoading]);

  useEffect(() => {
    const currentRef = ref.current;
    if (currentRef) {
      setIsAtEnd(isPillsAreInView(currentRef));
    }
  }, [ref.current?.scrollWidth, ref.current?.scrollLeft]);

  useEffect(() => {
    const carouselDiv = ref.current;
    if (carouselDiv) {
      carouselDiv.addEventListener('scroll', handleScroll);
      return () => {
        carouselDiv.removeEventListener('scroll', handleScroll);
      };
    }
  }, []);

  // Ensure last pill is fully in view when pill is selected
  useEffect(() => {
    const carouselDiv = ref.current;
    if (isLastPillClicked && carouselDiv) {
      carouselDiv.scrollBy({ left: 100 });
      setIsLastPillClicked(false);
    }
  }, [isLastPillClicked]);

  const hasPills = pills && pills?.length > 0;

  if (!hasPills) return null;

  if (isLoading) {
    return <LoadingPills />;
  }

  return hasPills ? (
    <Box
      position="relative"
      display="flex"
      alignItems="center"
      width="full"
      paddingLeft={{ mobile: isAtStart ? 'small' : 'none', tablet: 'none' }}
      paddingRight={{ mobile: isAtEnd ? 'small' : 'none', tablet: 'none' }}
    >
      {!isMobile && (
        <ScrollButton
          direction="left"
          label="Scroll left"
          handleOnClick={handleOnScrollButtonClick('left')}
          display={!isAtStart ? 'flex' : 'none'}
        />
      )}

      <Pills
        handleOnPillClick={handleOnPillClick}
        handleOnScroll={handleScroll}
        isAtEnd={isAtEnd}
        pills={pills}
        ref={ref}
      />

      {!isMobile && (
        <ScrollButton
          direction="right"
          label="Scroll right"
          handleOnClick={handleOnScrollButtonClick('right')}
          display={!isAtEnd ? 'flex' : 'none'}
        />
      )}
    </Box>
  ) : null;
};
